import Vue from 'vue'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

import {
  Header,
  Main,
  Row,
  Col,
  Button,
  Loading,
  Notification,
  Card,
  Select,
  Option,
  Progress,
  Input,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Image
} from 'element-ui'

locale.use(lang)

Vue.use(Header)
Vue.use(Main)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Card)
Vue.use(Select)
Vue.use(Option)
Vue.use(Progress)
Vue.use(Loading.directive)
Vue.use(Input)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Image)

Vue.prototype.$loading = Loading.service
Vue.prototype.$notify = Notification
