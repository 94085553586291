<template>
  <div id='secure'>
    <el-header>
      <div id="header-container" class="mb-20">
        <el-row :gutter="10" class="mt-10" type="flex">
          <el-col>
            <el-row :gutter="10">
              <el-col>
                <img id="logo" src="img/logo-blue.png" width="120px" @click="navigateToExperiences" />
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col>
                <span id="logo-sub">MODERATION</span>
              </el-col>
            </el-row>
          </el-col>

          <el-col>
            <Avatar />
          </el-col>
        </el-row>
      </div>
    </el-header>

    <el-main>
      <div id="main-container">
        <router-view />
      </div>
    </el-main>
  </div>
</template>

<script>
import Avatar from '@/components/header/Avatar.vue'

export default {
  name: 'Secure',
  components: {
    Avatar
  },

  methods: {
    navigateToExperiences (e) {
      if (this.$route.path !== '/experiences') {
        this.$router.push('/experiences')
      }

      e.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  background-color: #fff;
  color: #000;
  height: 90px !important;
}

#header-container {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

#main-container {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

#logo-sub {
  font-size: 0.8em;
  letter-spacing: 3.1px;
}

#logo:hover {
  cursor: pointer
}

.nav-experiences {
  color: #000;
  text-decoration: none;
  font-weight: bold;
  border-bottom: 4px solid rgb(248, 0, 75);
  line-height: 30px;
  padding-bottom: 10px;
}

.nav-experiences-xs {
  color: #fff;
  background-color: rgb(59, 71, 116);
  text-decoration: none;
  font-weight: bold;
  display: block;
  padding: 15px;
}

.nav-experiences-xs .square {
  color: rgb(248, 0, 75);
  font-size: 2.5em;
  margin-right: 10px;
}

.nav-experiences-xs .name {
  display: inline-block;
  line-height: 16px;
  height: 16px;
  vertical-align: super;
}
</style>
