<template>
  <div id='main'>
    <template v-if="!isAuthenticated">
      <Public>
        <router-view />
      </Public>
    </template>
    <template v-else>
      <Secure>
        <router-view />
      </Secure>
    </template>
  </div>
</template>

<script lang="js">
import Public from '@/views/public/Public'
import Secure from '@/views/secure/Secure'
import { mapGetters } from 'vuex'

export default {
  name: 'Main',

  components: {
    Public,
    Secure
  },

  computed: {
    ...mapGetters(['isAuthenticated'])
  },

  mounted () {
    this.checkAuth()
  },

  updated () {
    this.checkAuth()
  },

  methods: {
    checkAuth () {
      if (!this.isAuthenticated && this.$route.path !== '/') {
        this.$router.replace('/')
      }
    }
  }
}
</script>
