<template>
  <div id='app'>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  @import '@/styles/colours.scss';

  #app {
    background: url("/img/background.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
  }

  @media only screen and (min-width: 768px) {
    body {
      border: 20px solid #fff;
    }

    #app {
      min-height: calc(100vh - 40px);
    }
  }
</style>
