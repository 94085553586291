<template>
  <div id='public'>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Public'
}
</script>
