import Vue from 'vue'
import Router from 'vue-router'
import Main from './views/Main.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      component: Main,
      children: [
        {
          path: '',
          name: 'SignIn',
          component: () => import(/* webpackChunkName: 'sign-in' */ './views/public/sign-in/SignIn.vue')
        },
        {
          path: '/experiences',
          name: 'Experiences',
          component: () => import(/* webpackChunkName: 'experiences' */ './views/secure/experiences/Experiences.vue')
        },
        {
          path: '/experiences/:id',
          name: 'ModerateExperience',
          component: () => import(/* webpackChunkName: 'moderate-experience' */ './views/secure/experiences/ModerateExperience.vue')
        },
        {
          path: '/experiences/:id/audit',
          name: 'AuditExperience',
          component: () => import(/* webpackChunkName: 'audit-experience' */ './views/secure/experiences/Audit.vue')
        }
      ]
    }
  ]
})
