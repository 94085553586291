<template>
  <div class="avatar">
    <el-row type="flex" class="text--right">
      <el-col>
        <span class="name">{{ handle }}</span>
        <br>
        <el-button class="sign-out-btn" type="text" @click="onSignOutClicked">Logout</el-button>
      </el-col>
      <el-col style="width: 60px;">
        <img class="avatar-img" :src="imageUrl" height="40px" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Avatar',

  computed: {
    ...mapGetters(['handle', 'userProfilePicture']),

    imageUrl () {
      return this.userProfilePicture
    }
  },

  methods: {
    onSignOutClicked () {
      this.$store.commit('clearToken')
    }
  }
}
</script>

<style lang="scss" scoped>
.name {
  font-size: 0.9em;
  font-weight: bold;
}

.sign-out-btn {
  color: #000 !important;
  font-size: 0.75em;
  padding-top: 0;
  padding-right: 3px;
  font-weight: 400;
}

.avatar-img {
  border-radius: 25px;
}
</style>
