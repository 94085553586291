import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import ky from 'ky'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],

  state: {
    token: '',
    handle: '',
    moderatorId: '',
    firebaseToken: '',
    userProfilePicture: ''
  },

  getters: {
    isAuthenticated: state => {
      return !!state.token && state.token.length > 0
    },

    hasFirebaseToken: state => {
      return !!state.firebaseToken && state.firebaseToken.length > 0
    },

    token: state => state.token,
    moderatorId: state => state.moderatorId,
    handle: state => state.handle,
    userProfilePicture: state => state.userProfilePicture,
    firebaseToken: state => state.firebaseToken
  },

  mutations: {
    clearToken (state) {
      state.token = ''
      state.handle = ''
      state.firebaseToken = ''
    },

    setToken (state, token) {
      state.token = token
    },

    setHandle (state, handle) {
      state.handle = handle
    },

    setFirebaseToken (state, firebaseToken) {
      state.firebaseToken = firebaseToken
    },

    setModeratorId (state, moderatorId) {
      state.moderatorId = moderatorId
    },

    setUserProfilePicture (state, picture) {
        state.userProfilePicture = picture
    }
  },
  actions: {
    async validateToken () {
      const response = await ky.post(`${process.env.VUE_APP_API_URL}/auth/verify?${this.state.token}`)
      const { valid } = await response.json()

      if (!valid) {
        this.commit('clearToken')
      }
    }
  }
})
