import Rollbar from 'vue-rollbar'
import Vue from 'vue'
import VueKy from 'vue-ky-plugin'
import App from './App.vue'

import router from './router'
import store from './store'
import ky from 'ky'

import './plugins/element.js'
import './filters'
import './directives'

import 'font-awesome/scss/font-awesome.scss'
import 'element-ui/lib/theme-chalk/display.css'

import '@/styles/global.scss'
import '@/styles/utils.scss'
import '@/styles/element.scss'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { version } from '../package.json'

Vue.use(Rollbar, {
  accessToken: process.env.VUE_APP_ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.VUE_APP_ROLLBAR_ENABLED,
  environment: process.env.VUE_APP_ROLLBAR_ENVIRONMENT || 'development',
  payload: {
    client: {
      javascript: {
        code_version: version,
        source_map_enabled: true,
        guess_uncaught_frames: true
      }
    },
    person: {
      id: store.getters.moderatorId,
      username: store.getters.handle
    }
  }
})

Vue.config.errorHandler = err => {
  Vue.rollbar.error(err)
}

Vue.config.productionTip = false

firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID
})

const firestore = firebase.firestore()

firestore.settings({
  // use default settings
})

Vue.prototype.$firebase = firebase
Vue.prototype.$firestore = firestore

Vue.use(VueKy, ky.extend({
  throwHttpErrors: false,
  hooks: {
    beforeRequest: [
      request => {
        if (store.getters.isAuthenticated) {
          request.headers.set('Authorization', store.getters.token)
        }
      }
    ],
    afterResponse: [
      async (_request, _options, response) => {
        if (response.status >= 300) return response
        response.data = await response.json()
        return response
      }
    ]
  }
}))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
