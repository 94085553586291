import { utc } from 'moment'
import Vue from 'vue'

Vue.filter('epochAsDateTime', (epoch) => {
  if (!!epoch && epoch > 0) {
    return utc(epoch).format('YYYY-MM-DD HH:mm:ss')
  }

  return '-'
})

Vue.filter('epochAsDate', (epoch) => {
  if (!!epoch && epoch > 0) {
    if (typeof epoch === 'string') {
      return utc(parseInt(epoch)).format('DD MMMM YYYY')
    } else {
      return utc(epoch).format('DD MMMM YYYY')
    }
  }

  return '-'
})

Vue.filter('epochAsTimeOfDay', (epoch) => {
  if (!!epoch && epoch > 0) {
    if (typeof epoch === 'string') {
      return utc(parseInt(epoch)).format('HH:mm:ss')
    } else {
      return utc(epoch).format('HH:mm:ss')
    }
  }

  return '-'
})

Vue.filter('formatNumber', (value) => {
  if (!value) {
    return '0'
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.filter('decimal', (value, decimals) => {
  if (!value) {
    value = 0
  }

  if (!decimals) {
    decimals = 0
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)

  return value
})
